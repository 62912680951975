html,
body {
  height: 100%;
}

body {
  background-color: #4266dd;
  background-image: url("./images/Group1913.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 100%;
  color: white;
}

#root,
.App {
  height: 100%;
  position: relative;
}
.coming-soon {
  /* display: block; */
  position: inherit;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.top {
  flex: 0 1 auto;
}
.h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(230, 230, 230);
}

.h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

p {
  font-size: 18px;
  color: rgb(230, 230, 230);
}

.input-form {
  background-color: white;
  border: 1px solid rgb(56, 56, 56);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 42%;
  min-height: 50px;
}

.input-form input,
.input-form input:active,
.input-form input:focus {
  border: 0px !important;
  outline: none;
  border-color: white;
  background-color: white;
  height: 45px;
  width: auto;
  padding-left: 15px;
}

.input-form input::placeholder {
  color: #6c748f;
  font-size: 15px;
  padding-left: 15px;
}
.input-error {
  border: 1px solid red;
}

.text-success {
  color: rgb(149, 223, 149);
}

.text-danger {
  color: red;
}

.btn {
  font-size: 15px;
  letter-spacing: 0.4px;
  border-radius: 3px;
  padding: 12px 24px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-primary {
  background-color: #1643d5;
  border: 1px solid #1643d5;
  color: white;
  margin-left: auto;
}

footer {
  flex: 1 1 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: auto;
  gap: 20px;
}

.footer .follow-us {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer .follow-us p {
  font-size: 15px;
  font-weight: 600;
}

.footer .footer-icons a {
  margin: 0 5px;
}

.footer .copyright {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (max-width: 768px) {
  body {
    background-size: cover;
  }
  .input-form {
    width: 80%;
  }
}

@media (max-width: 425px) {
  .input-form {
    width: 90%;
  }
  .h1 {
    font-size: 2.7rem;
  }
  .h3 {
    font-size: 1.7rem;
  }
}
